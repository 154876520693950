"use client";

import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled more than one page height
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > window.innerHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  // Scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      {isVisible && (
        <button
          onClick={scrollToTop}
          className="flex items-center fixed bottom-10 -right-[104px] bg-primary text-white px-4 py-2 rounded-l-full shadow-lg hover:bg-black group h-12 hover:-right-0 transition-all duration-300 ease-in-out"
        >
          <Icon icon="mdi:chevron-up" className="h-6 w-6" />
          <span className="ml-2 ">Scroll to top</span>
        </button>
      )}
    </div>
  );
}
