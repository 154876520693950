"use client";
import { useState, useEffect } from "react";
import Link from "next/link";

export default function CookieBanner() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchConsent = async () => {
      const response = await fetch("/api/cookie");
      const data = await response.json();
      if (!data.consent) {
        const timer = setTimeout(() => {
          setOpen(true);
        }, 500);
        return () => clearTimeout(timer);
      }
    };
    fetchConsent();
  }, []);

  const handleDecline = async () => {
    setOpen(false);
    await fetch("/api/cookie", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ consent: "declined" }),
    });
  };

  const handleAllow = async () => {
    setOpen(false);
    await fetch("/api/cookie", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ consent: "allowed" }),
    });
  };

  return (
    <div className="flex flex-col">
      {open && (
        <div
          className="fixed max-w-7xl mx-auto bottom-10 inset-x-5 bg-white p-5 rounded-lg shadow-2xl flex gap-4 flex-wrap md:flex-nowrap text-center md:text-left items-center justify-center md:justify-between transition transform ease-in-out scale-100 opacity-100  z-50"
          style={{ transition: "all 0.3s ease" }}
        >
          <div className="w-full">
            This website uses cookies to ensure you get the best experience on
            our website.{" "}
            <Link
              href="/privacy"
              title="Learn more about our privacy policy"
              legacyBehavior
            >
              <a className="text-blue-900 hover:underline">
                Learn more about our privacy policy
              </a>
            </Link>
          </div>
          <div className="flex gap-4 items-center flex-shrink-0">
            <button
              onClick={handleDecline}
              className="text-indigo-600 focus:outline-none hover:underline"
            >
              Decline
            </button>
            <button
              onClick={handleAllow}
              className="bg-blue-700 px-5 py-2 text-white rounded-md hover:bg-indigo-700 focus:outline-none"
            >
              Allow Cookies
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
