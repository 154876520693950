"use client";

import Link from "next/link";
import * as prismic from "@prismicio/client";
import { PrismicText } from "@prismicio/react";
import { PrismicNextLink, PrismicNextImage } from "@prismicio/next";
import { useState, useEffect } from "react";
import Image from "next/image";
import Heading from "./Heading";
import { Button } from "./Button";
import PostcodeDialog from "./PostcodeDialog";

const Profile = ({ name, description, profilePicture }) => {
  return <></>;
};

const NavItem = ({ children }) => {
  return (
    <li className="font-semibold tracking-tight text-slate-800">{children}</li>
  );
};

export const Header = ({ navigation, settings }) => {
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  const logo = settings.data.logo;

  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    let scrollTimeout;
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const isMobile = window.innerWidth <= 768;

      if (scrollTop >= 1) {
        setIsSticky(true);
        if (scrollTop > lastScrollTop) {
          setIsVisible(true);
        } else {
          if (!isMobile) {
            setIsVisible(false);
            clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(() => {
              setIsVisible(true);
            }, 1000); // 1 second delay
          }
        }
      } else {
        setIsSticky(false);
        setIsVisible(true);
      }
      setLastScrollTop(scrollTop);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  return (
    <nav
      className={`bg-white border-gray-200 z-50 ${isSticky ? "sticky top-0  shadow-lg" : ""} ${isVisible ? "translate-y-0" : "-translate-y-full"} transition-transform duration-300`}
      style={isSticky ? { boxShadow: "rgba(0, 0, 0, 0.08) 0px 0px 15px" } : {}}
    >
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto py-6 px-4">
        <Link
          href="/"
          className="flex items-center space-x-3 rtl:space-x-reverse"
        >
          <Image
            src={logo.url}
            width={100}
            height={50}
            alt={logo.alt}
            style={{ paddingLeft: "10px" }}
          />
          <span className="self-center text-2xl font-semibold whitespace-nowrap">
            {prismic.asText(settings.data.siteName)}
          </span>
        </Link>
        <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse gap-5">
          <Link
            href="https://platform.getsetclean.co.uk/login-account-getsetclean"
            className="font-normal text-[14px] tracking-tight text-slate-800 flex items-center focus:outline-none focus:ring-4"
          >
            Log in
          </Link>
          <div>
            <PostcodeDialog />
          </div>

          <button
            onClick={toggleMenu}
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="navbar-dropdown"
            aria-expanded={menuOpen}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>
        <div
          className={`w-full md:block md:w-auto z-60 ${menuOpen ? "block" : "hidden"}`}
          id="navbar-dropdown"
        >
          <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0  z-60">
            {navigation.data?.links.map((item, index) => (
              <li key={prismic.asText(item.label)} className="relative group">
                {item.submenus && item.submenus.length > 0 ? (
                  <>
                    <button
                      id={`dropdownNavbarLink-${index}`}
                      onClick={() => toggleDropdown(index)}
                      className="flex text-[14px] items-center justify-between w-full py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-primary md:p-0 md:w-auto"
                    >
                      <PrismicText field={item.label} />
                      <svg
                        className="w-2.5 h-2.5 ms-2.5"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 1 4 4 4-4"
                        />
                      </svg>
                    </button>
                    <div
                      id={`dropdownNavbar-${index}`}
                      className="overflow-hidden absolute z-60 hidden group-hover:block md:group-hover:block font-normal bg-white divide-y divide-gray-100 rounded-lg shadow md:absolute md:top-full md:left-0 md:w-auto"
                    >
                      <ul
                        className="text-[14px] text-gray-700 hidden md:block w-48"
                        aria-labelledby={`dropdownNavbarLink-${index}`}
                      >
                        {item.submenus.map((submenu) => (
                          <li key={prismic.asText(submenu.label)}>
                            <PrismicNextLink
                              field={submenu.link}
                              legacyBehavior
                            >
                              <a className="block px-4 py-2 hover:bg-gray-100">
                                <PrismicText field={submenu.label} />
                              </a>
                            </PrismicNextLink>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </>
                ) : (
                  <PrismicNextLink field={item.link} legacyBehavior>
                    <a className="block py-2 px-3 text-[14px] text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-primary md:p-0">
                      <PrismicText field={item.label} />
                    </a>
                  </PrismicNextLink>
                )}
                {item.submenus && (
                  <ul
                    className={`flex flex-col pl-4 ${dropdownOpen === index ? "block" : "hidden"} md:hidden`}
                  >
                    {item.submenus.map((submenu) => (
                      <li key={prismic.asText(submenu.label)}>
                        <PrismicNextLink field={submenu.link} legacyBehavior>
                          <a className="block py-2 px-3 text-[14px] text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-primary md:p-0">
                            <PrismicText field={submenu.label} />
                          </a>
                        </PrismicNextLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};
